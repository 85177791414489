<template>
  <div class="container-card-management">
    <div class="dep-image"
         v-on:click="goTo"
         style="cursor: pointer">
      <img :src="depInfo.logo ? depInfo.logo : '/media/learn.jpg'" alt=""/>
      <div class="hover">
        <unicon name="eye" fill="white" height="48" width="48"/>
      </div>
      <div class="number basic-1 caption-caps-med-12"> {{index + 1}}</div>
    </div>
    <div class="dep-info">
      <div class="edit" v-on:click="clickCallback"
           style="cursor: pointer">
        <unicon name="ellipsis-v" fill="#333333"
                height="16" width="16" class="dropdown-news"/>
      </div>
      <div class="name"
           v-on:click="goTo"
           style="cursor: pointer">
        {{ depInfo.name }}

      </div>
      <div class="contains"
           v-on:click="goTo"
           style="cursor: pointer">
        <div class="ellipse" ></div>
        <div class="basic-2 me-2">{{ depInfo.branches.length }}
          {{ $t('DEPARTAMENTS.GROUPS') }} </div>
        <div class="ellipse" ></div>
        <div class="basic-2">{{ depInfo.students }}
          {{ $t('DEPARTAMENTS.STUDENTS') }} </div>
      </div>
      <div class="admin"
           v-on:click="goTo"
           style="cursor: pointer">
        <div v-if="depInfo.administrator_name">
          <img class="d-inline-block" :src="depInfo.administrator_image !== null ?
        depInfo.administrator_image : '/media/Frame 751.svg'" alt=""/>
          <div class="admin_name d-inline-block">
            <div class="headline-regular-16 basic-1">
              {{ depInfo.administrator_name }}
            </div>
            <div class="role basic-2">{{ $t('DEPARTAMENTS.FIELDS.ADMIN') }}</div>
          </div>
        </div>
      </div>
      <div
          v-on:click="goTo"
          style="cursor: pointer">
        <div class="students_success">
          <div class="position-relative">
            <div class="basic-2" style="height: fit-content">
              {{ $t('DEPARTAMENTS.STUDENTS_SUCCESS') }}:
            </div>
            <div style="position: absolute;right: 0;top:0;">
              <label class="success">{{ depInfo.students_success_green }}%</label>
              <label class="warning">{{depInfo.students_success_yellow}}%</label>
              <label class="danger">{{depInfo.students_success_red}}%</label>
            </div>
          </div>
          <b-progress :max="100">
            <b-progress-bar :value="depInfo.students_success_green" variant="success">
            </b-progress-bar>
            <b-progress-bar :value="depInfo.students_success_yellow" variant="warning">
            </b-progress-bar>
            <b-progress-bar :value="depInfo.students_success_red" variant="danger">
            </b-progress-bar>
          </b-progress>
        </div>
        <div class="students_success_right">
          <div class="float-end">
<!--            2%
            <unicon :name="index % 2 === 0 ? 'arrow-up' :'arrow-down'"
                    :fill="index % 2 === 0 ? '#00CA72' : '#E2445C'"
                    height="24" width="24"  style="margin-top: -4px;" />-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isObjectEmpty } from '@/shared/utils/globalMethods';

export default {
  name: 'ManagementCard',
  props: {
    notRendered: {
      type: Boolean,
      default: false,
    },
    depInfo: {
      type: Object,
    },
    id: {
      type: String,
      required: false,
    },
    index: {
      type: Number,
      required: false,
    },
    size: {
      type: String,
      default: 'medium',
    },
    block: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // Set a custom css class to the button
    customClass: String,
    // Defines a callback function which can be used to update parent state on change event
    callback: Function,
    // Defines a store action as string which can be used to update parent state on change event
    callbackAction: String,
    // Defines params for callback function or callback action
    callbackParams: {
      type: Object,
    },
    spinner: {
      type: Boolean,
      default: false,
    },
    to: String,
  },
  computed: {
    buttonStyleClasses() {
      const classes = [];
      if (this.customClass) {
        classes.push(this.customClass);
      }
      if (this.spinner) {
        classes.push('spinner spinner-right');
      }
      return classes;
    },
  },
  methods: {
    clickCallback() {
      if (this.callback) {
        if (!isObjectEmpty(this.callbackParams)) {
          this.callback(this.callbackParams);
        } else {
          this.callback();
        }
      }
    },
    goTo() {
      if (this.depInfo.parent_of !== null) {
        this.$router.push(`/management/${this.depInfo.parent_of}/${this.depInfo.id}`);
      } else if (this.depInfo.type === 'faculty') {
        this.$router.push(`/management/group/${this.depInfo.id}`);
      } else if (this.depInfo.type === 'department') {
        this.$router.push(`/management/group/${this.depInfo.id}`);
      } else {
        this.$router.push(`/management/student/${this.depInfo.id}`);
      }
    },
  },
};
</script>
